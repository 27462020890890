import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import type { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { IEnvironment } from '../../environments/models/environment.model';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@angular/fire/auth';
import { HttpHeaders } from '@angular/common/http';

export const getApolloProvider = (envConfig: IEnvironment) => {
  const { apiURL, graphQLEndpoint } = envConfig;
  const graphQLUrl = `${apiURL}${graphQLEndpoint}`;

  return {
    provide: APOLLO_OPTIONS,
    deps: [HttpLink, Auth],
    useFactory: (
      httpLink: HttpLink,
      auth: Auth
    ): ApolloClientOptions<NormalizedCacheObject> => {
      const graphQLLink = httpLink.create({
        uri: graphQLUrl,
        withCredentials: true,
      });

      const authCtx = setContext(async () => {
        const token = await auth.currentUser?.getIdToken();
        return {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        };
      });

      return {
        link: ApolloLink.from([authCtx, graphQLLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      };
    },
  };
};
