import { ApplicationConfig, importProvidersFrom, makeEnvironmentProviders, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from '../environments/environment';
import { provideHttpClient } from '@angular/common/http';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';

import { Apollo } from 'apollo-angular';
import { getApolloProvider } from './providers/apollo.provider';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StreamAutocompleteTextareaModule, StreamChatModule, StreamI18nService } from 'stream-chat-angular';

const { firebaseConfig } = environment;

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimationsAsync(),
    provideHttpClient(),
    importProvidersFrom(TranslateModule.forRoot()),
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => {
      const auth = getAuth();
      const { tenantId } = environment;
      auth.tenantId = tenantId;
      return auth;
    }),
    makeEnvironmentProviders([getApolloProvider(environment), Apollo]),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000 },
    },
    { provide: DateAdapter, useClass: DateFnsAdapter }, // Set DateFnsAdapter as the DateAdapter
    { provide: MAT_DATE_LOCALE, useValue: enUS },    // Set locale (default: 'en-US')
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'MM/dd/yyyy',
        },
        display: {
          dateInput: 'MM/dd/yyyy',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'MM/dd/yyyy',
          monthYearA11yLabel: 'MMMM yyyy',
        },
      },
    },
  ],
};
