<!-- Always present container for dynamic content -->
<ng-container #container></ng-container>

@if (show) {
  <div
    class="fixed inset-0 z-40 overflow-y-auto"
    role="dialog"
    aria-modal="true">
    <!-- Backdrop -->
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40"
      (click)="closeDialog()">
    </div>

    <!-- Dialog position wrapper -->
    <div class="relative flex min-h-full items-center justify-center p-4 text-center sm:p-0">
      <!-- Content container -->
      <div
        (click)="stopPropagation($event)"
        class="relative z-50 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <!-- The created component will be inserted here -->
        <ng-template #content></ng-template>
      </div>
    </div>
  </div>
}