import { Component } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RruleModule } from './shared/rrule-modal/rrule.module';
import { DialogComponent } from './shared/dialog/dialog.component';
import { HeaderComponent } from './home/shared/header/header.component';
import { filter } from 'rxjs/operators';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';

@Component({
  selector: 'hcp-root',
  imports: [
    RouterOutlet,
    CommonModule,
    RruleModule,
    HeaderComponent,
    DialogComponent,
    TranslateModule,
    StreamAutocompleteTextareaModule,
    StreamChatModule
  ],
  templateUrl: './app.component.html',
  standalone: true,
  styleUrl: './app.component.scss',
})
export class AppComponent {
  isLoginPage = false;

  constructor(private router: Router, private translateService: TranslateService) {
    this.translateService.use("en");

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.urlAfterRedirects === '/login';
      }
    });
  }
}
