import { Router, Routes } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { inject } from '@angular/core';
import { User } from 'firebase/auth';
import { of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';

export const appRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then(cmp => cmp.LoginComponent),
  },
  {
    path: 'home',
    title: 'Nervus',
    canActivateChild: [
      () => {
        const authService: Auth = inject(Auth);
        const router: Router = inject(Router);

        return authState(authService).pipe(
          switchMap((authenticatedUser: User | null) => {
            const userTenantId = authenticatedUser?.tenantId;
            if (!authenticatedUser || userTenantId !== environment.tenantId) {
              router.navigateByUrl('login');
            }
            return of(!!authenticatedUser);
          })
        );
      },
    ],
    loadChildren: () => import('./home/home.routes').then(routes => routes.routes),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'home',
  },
];
