import { Component, EventEmitter, Output } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'hcp-header',
  imports: [
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './header.component.html',
  standalone: true,
})
export class HeaderComponent {
  @Output()
  public toggleMenu: EventEmitter<void> = new EventEmitter<void>();
  showProfile: boolean = false;

  public constructor(
    public auth: Auth,
    private router: Router,
  ) {
  }

  public logoutUser(): void {
    this.auth.signOut().then(() => this.router.navigate(['/login']));
  }
}
