<header class=" inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
  <div class="mx-auto flex w-full max-w-10xl items-center justify-between px-4 sm:px-6 lg:px-8">
    <div class="flex flex-1 items-center gap-x-6">
      <button type="button" class="-m-3 p-3 md:hidden">
        <span class="sr-only">Open main menu</span>
        <svg class="size-5 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
          <path fill-rule="evenodd" d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75ZM2 10a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 10Zm0 5.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
        </svg>
      </button>
      <img class="h-8 w-auto" src="lumina-logo.svg" alt="Nervus Ltd.">
      <!-- Silly logo is 200 pixels wide, of which 80% is white space. -->
      <h1 style="margin-left: -140px;">Nervus</h1>
    </div>
    <nav class="hidden md:flex md:gap-x-11 md:text-sm/6 md:fonta-semibold md:text-gray-700">
      <a [routerLink]="['/home/dashboard']"
         routerLinkActive="text-indigo-600"
         [routerLinkActiveOptions]="{exact: true}"
         class="text-gray-700 hover:text-indigo-600">Dashboard</a>
      <a [routerLink]="['/home/patient']"
         routerLinkActive="text-indigo-600"
         class="text-gray-700 hover:text-indigo-600">Patients</a>
      <a [routerLink]="['/home/my-appointments']"
         routerLinkActive="text-indigo-600"
         class="text-gray-700 hover:text-indigo-600">Appointments</a>
      <a [routerLink]="['/home/chat']"
         routerLinkActive="text-indigo-600"
         class="text-gray-700 hover:text-indigo-600">Chat & Video</a>
    </nav>
    <div class="flex flex-1 items-center justify-end gap-x-8">
      <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
        <span class="sr-only">View notifications</span>
        <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
        </svg>
      </button>
      <a class="-m-1.5 p-1.5" (click)="showProfile = !showProfile">
        <span class="sr-only">Your profile</span>
        <img class="size-8 rounded-full bg-gray-800" [src]="auth.currentUser?.photoURL" alt="{{ auth.currentUser?.displayName }}">
      </a>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu open state. -->
  <div class="lg:hidden" role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0 z-50"></div>
    <div class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
      <div class="-ml-0.5 flex h-16 items-center gap-x-6">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700">
          <span class="sr-only">Close menu</span>
          <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
        <div class="-ml-0.5">
          <a href="#" class="-m-1.5 block p-1.5">
            <span class="sr-only">Nervus Ltd.</span>
            <img class="h-8 w-auto" src="lumina-logo.svg" alt="Nervus Ltd.">
          </a>
        </div>
      </div>
      <div class="mt-2 space-y-2">
        <a [routerLink]="['/home/dashboard']"
           routerLinkActive="text-indigo-600"
           [routerLinkActiveOptions]="{exact: true}"
           class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Dashboard</a>
        <a [routerLink]="['/home/patient']"
           routerLinkActive="text-indigo-600"
           class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Patients</a>
        <a [routerLink]="['/home/my-appointments']"
           routerLinkActive="text-indigo-600"
           class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Appointments</a>
        <a [routerLink]="['/home/chat']"
           routerLinkActive="text-indigo-600"
           class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Chat & Video</a>
      </div>
    </div>
  </div>

  <div class="relative z-10" role="dialog" aria-modal="true" [class.hidden]="!showProfile">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0"></div>

    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <!--
            Slide-over panel, show/hide based on slide-over state.

            Entering: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-full"
              To: "translate-x-0"
            Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-0"
              To: "translate-x-full"
          -->
          <div class="pointer-events-auto w-screen max-w-md">
            <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div class="px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2 id="slide-over-heading" class="text-base font-semibold text-gray-900">Profile</h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" (click)="showProfile = false">
                      <span class="absolute -inset-2.5"></span>
                      <span class="sr-only">Close panel</span>
                      <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Main -->
              <div>
                <div class="pb-1 sm:pb-6">
                  <div>
                    <div class="relative h-40 sm:h-56">
                      <img class="absolute size-full object-cover" [src]="auth.currentUser?.photoURL" alt="">
                    </div>
                    <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                      <div class="sm:flex-1">
                        <div>
                          <div class="flex items-center">
                            <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">{{ auth.currentUser?.displayName }}</h3>
                            <span class="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                            <span class="sr-only">Online</span>
                          </span>
                          </div>
                          <p class="text-sm text-gray-500">{{ auth.currentUser?.email }}</p>
                        </div>
                        <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          <button type="button"
                                  (click)="logoutUser()"
                                  class="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            Sign out
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                  <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6">
                    <div>
                      <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0">Bio</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                        <p>Enim feugiat ut ipsum, neque ut. Tristique mi id elementum praesent. Gravida in tempus feugiat netus enim aliquet a, quam scelerisque. Dictumst in convallis nec in bibendum aenean arcu.</p>
                      </dd>
                    </div>
                    <div>
                      <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0">Location</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">New York, NY, USA</dd>
                    </div>
                    <div>
                      <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0">Role</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">ashleyporter.com</dd>
                    </div>
                    <div>
                      <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0">Birthday</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                        <time datetime="1988-06-23">June 23, 1988</time>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>