import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef, ComponentRef, Injector, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from './dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class DialogComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container!: ViewContainerRef;

  private subscription: Subscription = new Subscription();
  private componentRef: ComponentRef<any> | null = null;
  show = false;
  disableClose = false;

  constructor(
    private dialogService: DialogService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.subscription = this.dialogService.dialog$.subscribe(dialog => {
      if (dialog) {
        const { component, config, dialogRef } = dialog;
        this.disableClose = config.disableClose || false;
        this.createComponent(component, config, dialogRef);
        this.show = true;
      } else {
        this.show = false;
        setTimeout(() => {
          if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
          }
        }, 300);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private createComponent(component: any, config: any, dialogRef: any) {
    // Clear any existing component
    this.container.clear();

    // Create a custom injector with dialog data and ref
    const customInjector = Injector.create({
      providers: [
        { provide: 'DIALOG_DATA', useValue: config.data },
        { provide: 'DIALOG_REF', useValue: dialogRef }
      ],
      parent: this.injector
    });

    // Create the component using the container
    this.componentRef = this.container.createComponent(component, {
      injector: customInjector
    });

    // Trigger change detection
    this.componentRef.changeDetectorRef.detectChanges();
  }

  closeDialog() {
    if (!this.disableClose) {
      this.dialogService.close();
    }
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
